import {
    inject,
    Injectable,
} from '@angular/core';

import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';

@Injectable({
    providedIn: 'root',
})
export class PlanUtilsService {
    private authenticationFacade = inject(AuthenticationFacade);

    private authorisationFacade = inject(AuthorisationFacade);

    isPublicOrFreemium(): boolean {
        return this.authorisationFacade.getPlanSnapshot()?.isFreemium || !this.authenticationFacade.getTokenSnapshot();
    }
}
