import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    forkJoin,
    of,
} from 'rxjs';
import {
    catchError,
    map,
} from 'rxjs/operators';

import { AdvancedFiltersUtilsService } from '~/app/shared/services/advanced-filters-utils/advanced-filters-utils.service';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { Collection } from '~/app/shared/types/collection.type';
import { Share } from '~/app/shared/types/shares/share.type';

@Injectable({
    providedIn: 'root',
})
export class SubSegmentationsService {
    constructor(
        private http: HttpClient,
        private advancedFiltersUtilsService: AdvancedFiltersUtilsService,
    ) {}

    getSharesBySubSegmentationId(id: number) {
        return this.http.get<Readonly<Collection<Share>>>(`/sub-segmentations/${id}/shares`);
    }

    getPromotedSharesBySubSegmentationId(id: number, query: AdvancedFilterGeneric[]) {
        const filters = this.advancedFiltersUtilsService.formatFilters(query, false, true);

        return this.http.post<Readonly<Collection<Share>>>(`/sub-segmentations/${id}/shares/promoted`, {
            filters,
        });
    }

    getSubSegmentationsWithShare(subSegmentationsIds: string[]) {
        const t = subSegmentationsIds
            .filter((id) => id !== '0')
            .map((id) => this.getSharesBySubSegmentationId(+id)
                .pipe(
                    catchError(() => of({ values: [] })),
                    map((results) => ({
                        id, shares: results.values,
                    })),
                ));

        return forkJoin(t);
    }
}
