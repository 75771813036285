import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AdvancedFiltersUtilsService } from '~/app/shared/services/advanced-filters-utils/advanced-filters-utils.service';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { FacetFamily } from '~/app/shared/types/facet/facet-family.type';
import { SavedSearchResponseData } from '~/app/shared/types/saved-search/saved-search-response-data';
import { SavedSearch } from '~/app/shared/types/saved-search/saved-search.type';

@Injectable({
    providedIn: 'root',
})
export class SavedSearchesService {
    public constructor(
        private http: HttpClient,
        private advancedFiltersUtilsService: AdvancedFiltersUtilsService,
    ) {}

    public getSavedSearches(facetFamilies: FacetFamily[]) {
        return this.http.get<Readonly<SavedSearchResponseData>>('/saved-searches', {
            params: {
                includeInformation: true,
            },
        }).pipe(
            map((result: Readonly<SavedSearchResponseData>): SavedSearch[] => result.values.map((value) => ({
                id: value.id,
                name: value.name,
                filters: this.advancedFiltersUtilsService.parseFilters(value.query, facetFamilies),
            }))),
        );
    }

    public postSavedSearch(library: string, filters: AdvancedFilterGeneric[]) {
        const formatted = this.advancedFiltersUtilsService.formatFilters(filters, true);

        return this.http.post('/saved-searches', {
            name: library,
            query: formatted,
        });
    }

    public putSavedSearch(library: { id: number, label: string }, filters: AdvancedFilterGeneric[]) {
        const formatted = this.advancedFiltersUtilsService.formatFilters(filters, true);

        return this.http.put(`/saved-searches/${library.id}`, {
            id: library.id,
            name: library.label,
            query: formatted,
        });
    }

    public deleteSavedSearch(id: number) {
        return this.http.delete(`/saved-searches/${id}`);
    }
}
