import { Injectable } from '@angular/core';
import {
    Action,
    Selector,
    State,
    StateContext,
    StateOperator,
    Store,
} from '@ngxs/store';
import {
    clone,
    equals,
    indexBy,
    insert,
    mergeDeepRight,
    mergeLeft,
    mergeRight,
    omit,
    pick,
    prop,
    reject,
    uniq,
} from 'ramda';
import {
    forkJoin,
    retry,
    timer,
} from 'rxjs';
import {
    switchMap,
    tap,
} from 'rxjs/operators';

import { PERMISSIONS } from '~/app/core/constants/permissions.constants';
import { ActiveRisksService } from '~/app/core/services/api/active-risks/active-risks.service';
import { NotationsService } from '~/app/core/services/api/notations/notations.service';
import { RiskIndicatorsService } from '~/app/core/services/api/risk-indicators/risk-indicators.service';
import { SavedSearchesService } from '~/app/core/services/api/saved-searches/saved-searches.service';
import { SearchUniversesService } from '~/app/core/services/api/search-universes/search-universes.service';
import { SharesService } from '~/app/core/services/api/shares/shares.service';
import { SubSegmentationsService } from '~/app/core/services/api/sub-segmentations/sub-segmentations.service';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { PlanUtilsService } from '~/app/core/utils/plan-utils/plan-utils.service';
import { EntityType } from '~/app/shared/enums/entity-type.enum';
import { ViewType } from '~/app/shared/enums/view-type.enum';
import { AdvancedFiltersUtilsService } from '~/app/shared/services/advanced-filters-utils/advanced-filters-utils.service';
import { BasePermissionsService } from '~/app/shared/services/base-permissions/base-permissions.service';
import { IndicatorsUtilsService } from '~/app/shared/services/indicators-utils/indicators-utils.service';
import { SortsUtilsService } from '~/app/shared/services/sorts-utils/sorts-utils.service';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { FacetFamily } from '~/app/shared/types/facet/facet-family.type';
import { SavedSearch } from '~/app/shared/types/saved-search/saved-search.type';
import { SearchQueryBody } from '~/app/shared/types/search/search-query-body.type';
import { CollectionOfShares } from '~/app/shared/types/shares/collection-of-shares.type';
import { ShareSelected } from '~/app/shared/types/shares/share-selected.type';
import { Share } from '~/app/shared/types/shares/share.type';
import { DisplayValue } from '~/app/shared/types/sort-display/display-value.type';
import { SortValue } from '~/app/shared/types/sort-display/sort-value.type';

import {
    AddShareAtIndexAction,
    AddShareListAction,
    AddSharesToSearchUniverseByShareIdsAction,
    AddSharesToSearchUniverseByShareSearchAction,
    AddSharesToSearchUniversesByShareIdsAction,
    AddSharesToSearchUniversesByShareSearchAction,
    ApplyDisplayValuesAction,
    ApplySortValuesAction,
    AutocompleteSearchAction,
    ChangeActiveShareListAction,
    ChangeSearchUniverseAction,
    ChangeSelectionAction,
    ChangeSubSegmentationAction,
    CloseShareListAction,
    CreateSearchUniverseByShareIdsAction,
    CreateSearchUniverseByShareSearchAction,
    DeleteSavedSearchAction,
    DeleteSearchUniverseAction,
    EmptyShares,
    GetMetaDataAction,
    GetPromotedSharesBySubSegmentationIdAction,
    GetRiskIndicatorsAction,
    GetSavedSearchesAction,
    GetSearchUniverseAction,
    GetSearchUniversesAction,
    GetSubSegmentationsAction,
    GetSubSegmentationsWithSharesAction,
    PostSavedSearchAction,
    PutSavedSearchAction,
    ResetCurrentDisplayValuesAction,
    ResetFiltersAction,
    ResetSelectedSavedSearchIdAction,
    SaveDefaultDisplayValuesAction,
    SearchAction,
    SelectAllAction,
    SetFiltersAction,
    SetSelectedSavedSearchIdAction,
    SetViewTypeAction,
    UnselectAllAction,
    UpdateSearchUniverseAction,
} from './shares-list.actions';
import {
    ENVESTBOARD_UNIVERSE_ID,
    SHARES_DEFAULT_DISPLAY_VALUE,
    SHARES_DEFAULT_FILTERS,
    SHARES_DEFAULT_SORT_OPTIONS,
    SHARES_SEARCH_FIELDS,
} from '../../constants/shares.constants';

export interface SubSegmentationsModel {
    id: number,
    name: string,
    code: string,
    count: number,
    active?: boolean,
    shares?: Array<number>,
}

export interface SearchUniverseModel {
    id: number,
    name: string,
    count: number,
}

export interface SharesListModel {
    key: string,
    isLoading: boolean,
    isLoadingPromoted: boolean,
    indicatorsIsLoading: { [shareId: string]: boolean },
    isTrending: boolean,
    query: string,
    currentSubSegmentation: number,
    currentUniverse: number,
    currentSavedSearchId: number | null,
    extraFilters: Array<AdvancedFilterGeneric>,
    filters: Array<AdvancedFilterGeneric>,
    filtersCount: number,
    selected: Array<ShareSelected>,
    shares: Omit<CollectionOfShares<number>, 'metadata'>,
    promotedShares: Array<number>,
    displayValues: DisplayValue[],
    sortValues: SortValue[],
    trendingShares: Array<number>,
    alreadyQuitPromotedShare: boolean,
    viewType: ViewType,
}

export interface SharesListStateModel {
    currentShareList: string,
    facets: FacetFamily[],
    searchUniverse: { [key: string] : SearchUniverseModel },
    shares: { [key: number] : Share },
    subSegmentations: { [key: string] : SubSegmentationsModel },
    defaultDisplayValues: DisplayValue[],
    sharesList: { [key: string]: SharesListModel },
    savedSearches: SavedSearch[],
    searchQueryBody: SearchQueryBody,
    createdUniverseId?: number, // TODO refactor this property :/
}

const defaults = {
    currentShareList: '',
    facets: [],
    searchUniverse: {},
    shares: {},
    subSegmentations: {},
    defaultDisplayValues: SHARES_DEFAULT_DISPLAY_VALUE,
    savedSearches: [],
    sharesList: {},
    currentSavedSearchId: null,
    searchQueryBody: {} as SearchQueryBody,
};

function isLoading() : StateOperator<SharesListStateModel> {
    return (state: Readonly<SharesListStateModel>) => ({
        ...state,
        sharesList: {
            ...state.sharesList,
            [state.currentShareList]: {
                ...state.sharesList[state.currentShareList],
                isLoading: true,
            },
        },
    });
}

function onChangeCurrentShareList(change: { [key: string]: any }) : StateOperator<SharesListStateModel> {
    return (state: Readonly<SharesListStateModel>) => ({
        ...state,
        sharesList: {
            ...state.sharesList,
            [state.currentShareList]: {
                ...state.sharesList[state.currentShareList],
                ...change,
            },
        },
    });
}

@State<SharesListStateModel>({
    name: 'sharesList',
    defaults,
})
@Injectable()
export class SharesListState {
    constructor(
        private sharesService: SharesService,
        private store: Store,
        private riskIndicatorsService: RiskIndicatorsService,
        private notationsService: NotationsService,
        private activeRisksService: ActiveRisksService,
        private searchUniversesService: SearchUniversesService,
        private subSegmentationsService: SubSegmentationsService,
        private indicatorsUtilsService: IndicatorsUtilsService,
        private sortsUtilsService: SortsUtilsService,
        private savedSearchesService: SavedSearchesService,
        private advancedFiltersUtilsService: AdvancedFiltersUtilsService,
        private authenticationFacade: AuthenticationFacade,
        private authorisationService: BasePermissionsService,
        private planUtilsService : PlanUtilsService,
    ) {}

    @Selector()
    static getQuery(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].query;
    }

    @Selector()
    static currentShareListViewType(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].viewType;
    }

    @Selector()
    static getSubSegmentations(state: SharesListStateModel) {
        return Object.values(state.subSegmentations).map((item) => ({
            key: item.id,
            label: item.name,
            number: item.count,
        }));
    }

    @Selector()
    static getSubSegmentationsWithShares(state: SharesListStateModel) {
        return Object.values(state.subSegmentations)
            .filter((item) => item.id !== 0)
            .map((item) => ({
                ...item, shares: item.shares?.map((r) => state.shares[r]),
            }));
    }

    @Selector()
    static getCurrentSubSegmentation(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].currentSubSegmentation;
    }

    @Selector()
    static getCreatedUniverseId(state: SharesListStateModel) {
        return state.createdUniverseId;
    }

    @Selector()
    static getPromotedSharesId(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].promotedShares;
    }

    @Selector()
    static getPromotedShares(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].promotedShares.map((r) => state.shares[r]).filter((r) => r);
    }

    @Selector()
    static getShares(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].shares.values.map((r) => state.shares[r]).filter((r) => r);
    }

    @Selector()
    static getRequestIdOfShares(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].shares.requestId;
    }

    @Selector()
    static getSearchQueryBody(state: SharesListStateModel): SearchQueryBody {
        return state.searchQueryBody;
    }

    @Selector()
    static getSearchUniverses(state: SharesListStateModel) {
        return Object.values(state.searchUniverse);
    }

    @Selector()
    static getCurrentSearchUniverse(state: SharesListStateModel) {
        return state.searchUniverse[state.sharesList[state.currentShareList].currentUniverse];
    }

    @Selector()
    static getCurrentSavedSearchId(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].currentSavedSearchId;
    }

    @Selector()
    static getCurrentSearchUniverseId(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].currentUniverse;
    }

    @Selector()
    static getSelectedShares(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].selected;
    }

    @Selector()
    static getSelectedShareIds(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].selected.map((selectedShare) => selectedShare.id);
    }

    @Selector()
    static getDefaultDisplayValues(state: SharesListStateModel) {
        return state.defaultDisplayValues;
    }

    @Selector()
    static getDisplayIndicatorShare(state: SharesListStateModel): DisplayValue[] {
        return state.sharesList[state.currentShareList].displayValues;
    }

    @Selector()
    static isSearchDisabled(state: SharesListStateModel) {
        const isSearchLoading = this.isLoading(state);
        const sharesLength = state.sharesList[state.currentShareList].shares.values.length;
        const { totalNumber } = state.sharesList[state.currentShareList].shares;

        return sharesLength >= totalNumber || isSearchLoading;
    }

    @Selector()
    static isTrending(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].isTrending;
    }

    @Selector()
    static alreadyQuitPromotedShare(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].alreadyQuitPromotedShare;
    }

    @Selector()
    static isLoading(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].isLoading;
    }

    @Selector()
    static isLoadingPromoted(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].isLoadingPromoted;
    }

    @Selector()
    static indicatorsIsLoading(state: SharesListStateModel) {
        return state.sharesList[state.currentShareList].indicatorsIsLoading;
    }

    @Selector()
    static getCurrentSortValue(state: SharesListStateModel): SortValue[] {
        return state.sharesList[state.currentShareList].sortValues;
    }

    @Selector()
    static getCurrentDisplayCount(state: SharesListStateModel): number {
        return state.sharesList[state.currentShareList].displayValues.length;
    }

    @Selector()
    static getCurrentSortCount(state: SharesListStateModel): number {
        return state.sharesList[state.currentShareList].sortValues.length;
    }

    @Selector()
    static facets(state: SharesListStateModel): FacetFamily[] {
        return state.facets;
    }

    @Selector()
    static currentFilters(state: SharesListStateModel): AdvancedFilterGeneric[] {
        return state.sharesList[state.currentShareList].filters;
    }

    @Selector()
    static filtersCount(state: SharesListStateModel): number {
        return state.sharesList[state.currentShareList].filters?.reduce((acc, filter) => {
            if (filter.value.values) {
                const filterValue = filter.value?.values;
                return Array.isArray(filterValue) && filterValue?.length > 0
                    ? acc + filterValue?.length
                    : acc + 1;
            }
            if (filter.value.periods) {
                return filter.value.periods.reduce((acc2) => acc2 + 1, acc);
            }
            if (typeof filter?.value?.value !== 'undefined' && filter?.value?.value !== null) {
                return acc + 1;
            }
            if (filter.value.min || filter.value.max) {
                return acc + 1;
            }
            if (filter.value.properties && filter.value.properties.length) return acc + filter.value.properties.length;
            return acc;
        }, 0);
    }

    @Selector()
    static savedSearches(state: SharesListStateModel): SavedSearch[] {
        return state.savedSearches;
    }

    @Selector()
    static currentSavedSearchId(state: SharesListStateModel): number | null {
        return state.sharesList[state.currentShareList].currentSavedSearchId;
    }

    @Selector()
    static currentShareList(state: SharesListStateModel): string {
        return state.currentShareList;
    }

    @Action(GetPromotedSharesBySubSegmentationIdAction, { cancelUncompleted: true })
    getPromotedSharesBySubSegmentationId({ getState, setState, patchState, dispatch }: StateContext<SharesListStateModel>) {
        const currentState = getState();

        setState(onChangeCurrentShareList({ isLoadingPromoted: true }));

        const currentSubSegmentationId = currentState.sharesList[currentState.currentShareList].currentSubSegmentation;
        const { filters, extraFilters } = currentState.sharesList[currentState.currentShareList];
        return this.subSegmentationsService.getPromotedSharesBySubSegmentationId(currentSubSegmentationId, [...filters, ...(extraFilters ?? [])])
            .pipe(
                tap((results) => {
                    const state = getState();
                    patchState({
                        shares: mergeDeepRight(indexBy(prop('id'), results.values), state.shares),
                        sharesList: {
                            ...state.sharesList,
                            [state.currentShareList]: {
                                ...state.sharesList[state.currentShareList],
                                promotedShares: results.values.map((share) => share.id),
                                isLoadingPromoted: false,
                            },
                        },
                    });
                }),
                tap((results) => {
                    dispatch(new GetRiskIndicatorsAction(results.values.map((share) => share.id)));
                }),
            );
    }

    @Action(EmptyShares)
    emptyShares({ getState, patchState }: StateContext<SharesListStateModel>, action: EmptyShares) {
        const state = getState();
        const rest = pick(action.sharesId, state.shares);
        patchState({
            shares: { ...rest },
        });
    }

    // ICI
    @Action(SearchAction, { cancelUncompleted: true })
    search({ getState, patchState, setState, dispatch }: StateContext<SharesListStateModel>, action: SearchAction) {
        let currentState = getState();
        if (action.reset) {
            patchState({
                sharesList: {
                    ...currentState.sharesList,
                    [currentState.currentShareList]: {
                        ...currentState.sharesList[currentState.currentShareList],
                        shares: {
                            size: 10,
                            totalNumber: 0,
                            startAt: 0,
                            requestId: '',
                            values: [],
                        },
                    },
                },
            });
        }
        currentState = getState();

        const { displayValues, sortValues, filters, extraFilters } = currentState.sharesList[currentState.currentShareList];
        const fields = this.indicatorsUtilsService.createFieldsForRequest(displayValues, [], SHARES_SEARCH_FIELDS.join(','));
        const sorts = this.sortsUtilsService.createSortsForRequest(sortValues);
        const formatFilters = this.advancedFiltersUtilsService.formatFilters([...filters, ...(extraFilters ?? [])], false, false, currentState.facets);
        const refreshMetadata = !currentState.sharesList[currentState.currentShareList].shares.requestId;

        setState(isLoading());

        const searchQueryBody: SearchQueryBody = {
            fields,
            q: currentState.sharesList[currentState.currentShareList].query,
            filters: formatFilters,
            sorts,
            searchUniverse: currentState.sharesList[currentState.currentShareList].currentUniverse,
            subSegmentation: currentState.subSegmentations[currentState.sharesList[currentState.currentShareList].currentSubSegmentation]?.code || 'ALL',
            includeMetadata: false,
            startAt: currentState.sharesList[currentState.currentShareList].shares.startAt,
            size: 10,
            requestId: currentState.sharesList[currentState.currentShareList].shares.requestId,
        };
        return this.sharesService.search(searchQueryBody)
            .pipe(
                tap((results) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { metadata, ...rest } = results;
                    const state = getState();
                    patchState({
                        searchQueryBody,
                        shares: mergeLeft(indexBy(prop('id'), results.values), state.shares),
                        sharesList: {
                            ...state.sharesList,
                            [state.currentShareList]: {
                                ...state.sharesList[state.currentShareList],
                                isLoading: false,
                                indicatorsIsLoading: results.values.reduce((acc, current) => ({
                                    ...acc,
                                    [current.id]: true,
                                }), {} as ({ [key: string]: boolean })),
                                shares: {
                                    ...rest,
                                    startAt: rest.values[rest.values.length - 1]?.id,
                                    values: [
                                        ...new Set([
                                            ...(currentState.sharesList[currentState.currentShareList].shares.values ?? []),
                                            ...rest.values.map((share) => share.id),
                                        ]),
                                    ],
                                },
                            },
                        },
                    });
                }),
                tap((results) => {
                    dispatch(new GetRiskIndicatorsAction(results.values.map((share) => share.id)));
                    if (refreshMetadata) {
                        dispatch(new GetMetaDataAction(currentState.sharesList[currentState.currentShareList].currentUniverse));
                        dispatch(new GetSubSegmentationsAction({
                            searchUniverse: currentState.sharesList[currentState.currentShareList].currentUniverse,
                            filters: formatFilters,
                        }));
                    }
                }),
                retry({
                    count: 1,
                    delay: () => {
                        patchState({
                            sharesList: {
                                ...currentState.sharesList,
                                [currentState.currentShareList]: {
                                    ...currentState.sharesList[currentState.currentShareList],
                                    shares: {
                                        size: 10,
                                        totalNumber: 0,
                                        startAt: 0,
                                        requestId: '',
                                        values: [],
                                    },
                                },
                            },
                        });
                        return timer(1000);
                    },
                }),

            );
    }

    @Action(AutocompleteSearchAction)
    autocompleteSearch({ setState }: StateContext<SharesListStateModel>, action: AutocompleteSearchAction) {
        setState(onChangeCurrentShareList({
            query: action.q,
            isTrending: false,
            sortValues: [],
            ...(
                action.resetFilters
                    ? {
                        currentSavedSearchId: -1,
                        filters: [],
                        filtersCount: 0,
                    } : {}),
            ...(
                action.resetSubsegmentations
                    ? {
                        currentSubSegmentation: 0,
                    }
                    : {}
            ),
        }));
    }

    @Action(GetMetaDataAction)
    getMetaData({ patchState }: StateContext<SharesListStateModel>, action: GetMetaDataAction) {
        return this.sharesService.getSharesMetadata({
            searchUniverse: action.searchUniverse,
            filters: action.filters,
        })
            .pipe(
                tap((facetFamilies) => {
                    patchState({
                        facets: facetFamilies.reduce((acc: FacetFamily[], facetFamily) => {
                            if (!acc.find((fFamily) => fFamily.name === facetFamily.name)) {
                                acc.push(facetFamily);
                            }
                            return acc;
                        }, []),
                    });
                }),
            );
    }

    @Action(GetSubSegmentationsAction)
    getSubSegmentations({ patchState }: StateContext<SharesListStateModel>, action: GetSubSegmentationsAction) {
        return this.sharesService.getSharesSearchSubSegmentations(action.args)
            .pipe(
                tap((subSegmentations) => {
                    patchState({
                        subSegmentations: indexBy(prop('id'), subSegmentations),
                    });
                }),
            );
    }

    @Action(GetSearchUniversesAction)
    getSearchUniverses({ patchState }: StateContext<SharesListStateModel>) {
        return this.searchUniversesService.getAll()
            .pipe(
                tap((results) => {
                    patchState({
                        searchUniverse: indexBy(prop('id'), results.values),
                    });
                }),
            );
    }

    @Action(GetSearchUniverseAction)
    getSearchUniverse({ getState, patchState }: StateContext<SharesListStateModel>, action: GetSearchUniverseAction) {
        const state = getState();
        return this.searchUniversesService.getUniverseById(action.universeId).pipe(
            tap((result) => {
                patchState({
                    searchUniverse: {
                        ...state.searchUniverse,
                        [action.universeId]: {
                            ...result,
                        },
                    },
                });
            }),
        );
    }

    @Action(CreateSearchUniverseByShareSearchAction)
    createSearchUniverseByShareSearch(
        { getState, setState, patchState, dispatch }: StateContext<SharesListStateModel>,
        action: CreateSearchUniverseByShareSearchAction,
    ) {
        return this.searchUniversesService.create(action.name)
            .pipe(
                switchMap((result) => this.searchUniversesService.addSharesByUniverseIdAndShareSearchRequest(result.id, action.shareSearch)
                    .pipe(
                        tap(() => {
                            const state = getState();

                            patchState({
                                createdUniverseId: result.id,
                                searchUniverse: {
                                    ...state.searchUniverse,
                                    [result.id]: {
                                        ...result,
                                        count: action.numberShares,
                                    },
                                },
                            });

                            if (action.emptySelected) {
                                setState(onChangeCurrentShareList({ selected: [] }));
                            }


                            if (!action.mustStay) { dispatch(new ChangeSearchUniverseAction(result.id)); }
                        }),
                    )),
            );
    }

    @Action(CreateSearchUniverseByShareIdsAction)
    createSearchUniverseByShareIds(
        { getState, setState, patchState, dispatch }: StateContext<SharesListStateModel>,
        action: CreateSearchUniverseByShareIdsAction,
    ) {
        return this.searchUniversesService.create(action.name)
            .pipe(
                switchMap((result) => this.searchUniversesService.addSharesByUniverseIdAndShareIds(result.id, action.sharesId)
                    .pipe(
                        tap(() => {
                            const state = getState();

                            patchState({
                                searchUniverse: {
                                    ...state.searchUniverse,
                                    [result.id]: {
                                        ...result,
                                        count: action.sharesId.length,
                                    },
                                },
                            });

                            if (action.emptySelected) {
                                setState(onChangeCurrentShareList({ selected: [] }));
                            }

                            if (!action.mustStay) { dispatch(new ChangeSearchUniverseAction(result.id)); }
                        }),
                    )),
            );
    }

    @Action(DeleteSearchUniverseAction)
    deleteSearchUniverse({ getState, patchState, dispatch }: StateContext<SharesListStateModel>, action: DeleteSearchUniverseAction) {
        return this.searchUniversesService.deleteUniverseById(action.universeId)
            .pipe(
                tap(() => {
                    const state = getState();

                    patchState({
                        searchUniverse: Object.entries(state.searchUniverse).reduce((acc: { [key: string]: SearchUniverseModel }, [key, value]) => {
                            if (parseInt(key, 10) === action.universeId) {
                                return acc;
                            }
                            return {
                                ...acc,
                                [key]: value,
                            };
                        }, {}),
                        sharesList: Object.entries(state.sharesList).reduce((acc: { [key: string]: SharesListModel }, [key, value]) => ({
                            ...acc,
                            [key]: {
                                ...value,
                                currentUniverse: action.universeId === value.currentUniverse ? ENVESTBOARD_UNIVERSE_ID : value.currentUniverse,
                            },
                        }), {}),
                    });

                    if (action.universeId === state.sharesList[state.currentShareList].currentUniverse) { dispatch(new SearchAction(true)); }
                }),
            );
    }

    @Action(UpdateSearchUniverseAction)
    updateSearchUniverse({ getState, patchState }: StateContext<SharesListStateModel>, action: UpdateSearchUniverseAction) {
        return this.searchUniversesService.update(action.id, action.name)
            .pipe(
                tap(() => {
                    const state = getState();

                    patchState({
                        searchUniverse: {
                            ...(state.searchUniverse || {}),
                            [action.id]: {
                                ...state.searchUniverse[action.id],
                                name: action.name,
                            },
                        },
                    });
                }),
            );
    }

    @Action(AddSharesToSearchUniversesByShareSearchAction)
    addSharesToSearchUniversesByShareSearch({ setState, dispatch }: StateContext<SharesListStateModel>, action: AddSharesToSearchUniversesByShareSearchAction) {
        return this.searchUniversesService.addSharesByUniversesIdsAndShareSearchRequest(action.universesIds, action.shareSearch)
            .pipe(
                tap(() => {
                    if (action.clearSelected) {
                        setState(onChangeCurrentShareList({ selected: [] }));
                    }
                    if (action.refreshUniverses) {
                        dispatch(new GetSearchUniversesAction());
                    }
                }),
            );
    }

    @Action(AddSharesToSearchUniverseByShareSearchAction)
    addSharesToSearchUniverseByShareSearch({ setState, dispatch }: StateContext<SharesListStateModel>, action: AddSharesToSearchUniverseByShareSearchAction) {
        return this.searchUniversesService.addSharesByUniverseIdAndShareSearchRequest(action.universeId, action.shareSearch)
            .pipe(
                tap(() => {
                    if (action.emptySelected) { setState(onChangeCurrentShareList({ selected: [] })); }

                    dispatch(new GetSearchUniverseAction(action.universeId));
                }),
            );
    }

    @Action(AddSharesToSearchUniversesByShareIdsAction)
    addSharesToSearchUniversesByShareIds({ setState, dispatch }: StateContext<SharesListStateModel>, action: AddSharesToSearchUniversesByShareIdsAction) {
        return this.searchUniversesService.addSharesByUniversesIdsAndShareIds(action.universesIds, action.sharesIds)
            .pipe(
                tap(() => {
                    if (action.clearSelected) {
                        setState(onChangeCurrentShareList({ selected: [] }));
                    }
                    if (action.refreshUniverses) {
                        dispatch(new GetSearchUniversesAction());
                    }
                }),
            );
    }

    @Action(AddSharesToSearchUniverseByShareIdsAction)
    addSharesToSearchUniverseByShareIds({ setState, dispatch }: StateContext<SharesListStateModel>, action: AddSharesToSearchUniverseByShareIdsAction) {
        return this.searchUniversesService.addSharesByUniverseIdAndShareIds(action.universeId, action.sharesId)
            .pipe(
                tap(() => {
                    if (action.emptySelected) { setState(onChangeCurrentShareList({ selected: [] })); }

                    dispatch(new GetSearchUniverseAction(action.universeId));
                }),
            );
    }

    @Action(ChangeSearchUniverseAction)
    changeSearchUniverse({ getState, setState }: StateContext<SharesListStateModel>, action: ChangeSearchUniverseAction) {
        const state = getState();
        setState(onChangeCurrentShareList({
            currentUniverse: action.universeId,
            selected: [],
            ...(action.universeId !== ENVESTBOARD_UNIVERSE_ID
                ? { isTrending: false }
                : { isTrending: this.authorisationService.hasPermissions([PERMISSIONS.FUND_SEARCH_PROMOTED_ACCESS])
                        && !state.sharesList[state.currentShareList].alreadyQuitPromotedShare }),
        }));
    }

    @Action(GetSubSegmentationsWithSharesAction)
    getSubSegmentationsWithShares({ getState, patchState, setState, dispatch }: StateContext<SharesListStateModel>) {
        let state = getState();

        setState(isLoading());

        const { filters, extraFilters } = state.sharesList[state.currentShareList];
        const formatFilters = this.advancedFiltersUtilsService.formatFilters([...filters, ...(extraFilters ?? [])], false, false, state.facets);

        return this.sharesService.getSharesSearchSubSegmentations({
            searchUniverse: state.sharesList[state.currentShareList].currentUniverse,
            filters: formatFilters,
        }).pipe(
            switchMap((foundSubSegmentations) => this.subSegmentationsService
                .getSubSegmentationsWithShare(foundSubSegmentations.map((subSeg) => subSeg.id.toString()))
                .pipe(
                    tap((results) => {
                        let subSegmentations = {};
                        let shares = {};
                        let trendingShare : Array<number> = [];

                        state = getState();
                        results.forEach((item) => {
                            const shareClone = mergeRight(state.shares, indexBy(prop('id'), item.shares));
                            const shareIds = item.shares.map((share) => share.id);

                            shares = {
                                ...shares,
                                ...shareClone,
                            };

                            trendingShare = [
                                ...trendingShare,
                                ...shareIds,
                            ];

                            subSegmentations = {
                                ...subSegmentations,
                                [item.id]: {
                                    ...state.subSegmentations[item.id],
                                    shares: shareIds,
                                },
                            };
                        });

                        patchState({
                            shares,
                            subSegmentations: {
                                ...state.subSegmentations,
                                ...subSegmentations,
                            },
                            sharesList: {
                                ...state.sharesList,
                                [state.currentShareList]: {
                                    ...state.sharesList[state.currentShareList],
                                    trendingShares: [...new Set(trendingShare)],
                                    isLoading: false,
                                },
                            },
                        });
                    }),
                    tap(() => {
                        state = getState();
                        dispatch(new GetRiskIndicatorsAction(state.sharesList[state.currentShareList].trendingShares));
                    }),
                )),
        );
    }

    @Action(GetRiskIndicatorsAction)
    getRiskIndicatorsData({ getState, setState }: StateContext<SharesListStateModel>, action: GetRiskIndicatorsAction) {
        let state = getState();
        const displayIndicator = state.sharesList[state.currentShareList].displayValues;
        const indicatorsParams = this.indicatorsUtilsService.createIndicatorsForRequests(displayIndicator);

        return forkJoin([
            this.riskIndicatorsService.getRiskIndicatorsValuesForSharesOrCategories(action.sharesId, [], indicatorsParams.defaultRiskIndicators),
            this.activeRisksService.getActiveRisksValuesForSharesOrCategories(action.sharesId, [], indicatorsParams.defaultActiveRisks),
            this.notationsService.getNotationsValuesForSharesOrCategories(action.sharesId, [], indicatorsParams.defaultNotations),
        ]).pipe(
            tap(([resultRiskIndicators, resultActiveRisks, resultNotations]) => {
                state = getState();
                const shares = clone(state.shares);

                for (let i = 0; i < resultRiskIndicators.length; i += 1) {
                    if (resultRiskIndicators[i].type === EntityType.SHARE && shares[resultRiskIndicators[i].id]) {
                        shares[resultRiskIndicators[i].id].riskIndicators = resultRiskIndicators[i].riskIndicators;
                    }
                }

                for (let j = 0; j < resultActiveRisks.length; j += 1) {
                    if (resultActiveRisks[j].type === EntityType.SHARE && shares[resultActiveRisks[j].id]) {
                        shares[resultActiveRisks[j].id].activeRisks = resultActiveRisks[j].activeRisks;
                    }
                }

                for (let k = 0; k < resultNotations.length; k += 1) {
                    if (resultNotations[k].type === EntityType.SHARE && shares[resultNotations[k].id]) {
                        shares[resultNotations[k].id].notations = resultNotations[k].notations;
                    }
                }

                setState({
                    ...state,
                    shares,
                    sharesList: {
                        ...state.sharesList,
                        [state.currentShareList]: {
                            ...state.sharesList[state.currentShareList],
                            indicatorsIsLoading: {},
                        },
                    },
                });
            }),
        );
    }

    @Action(ChangeSubSegmentationAction)
    changeSubSegmentation({ setState }: StateContext<SharesListStateModel>, action: ChangeSubSegmentationAction) {
        setState(onChangeCurrentShareList({
            currentSubSegmentation: action.subSegmentationId,
            isTrending: false,
            alreadyQuitPromotedShare: true,
        }));
    }

    @Action(AddShareListAction)
    addShareList(ctx: StateContext<SharesListStateModel>, action: AddShareListAction) {
        const state = ctx.getState();

        const userCurrency = this.authenticationFacade.getUserCurrencySnapshot();
        const isTrendingAuthorised = this.authorisationService.hasPermissions([PERMISSIONS.FUND_SEARCH_PROMOTED_ACCESS]);
        if (!state.sharesList[action.key] || action.overrideList) {
            ctx.patchState({
                currentShareList: action.key,
                sharesList: {
                    ...state.sharesList,
                    [action.key]: {
                        key: action.key,
                        isLoading: false,
                        isLoadingPromoted: false,
                        indicatorsIsLoading: {},
                        isTrending: isTrendingAuthorised,
                        query: '',
                        currentSubSegmentation: 0,
                        currentUniverse: 0,
                        alreadyQuitPromotedShare: false,
                        selected: [],
                        extraFilters: action.extraFilters,
                        filters: SHARES_DEFAULT_FILTERS(userCurrency ?? 'EUR'),
                        filtersCount: SHARES_DEFAULT_FILTERS(userCurrency ?? 'EUR').length,
                        promotedShares: [],
                        trendingShares: [],
                        sortValues: [],
                        displayValues: this.planUtilsService.isPublicOrFreemium() ? SHARES_DEFAULT_DISPLAY_VALUE : state.defaultDisplayValues,
                        viewType: ViewType.LIST,
                        shares: {
                            size: 10,
                            totalNumber: 0,
                            startAt: 0,
                            requestId: '',
                            values: [],
                        },
                        currentSavedSearchId: -1,
                    },
                },
            });
        } else {
            ctx.patchState({
                currentShareList: action.key,
            });
        }
    }

    @Action(ChangeActiveShareListAction)
    changeActiveShareList(ctx: StateContext<SharesListStateModel>, action: ChangeActiveShareListAction) {
        ctx.patchState({ currentShareList: action.key });
    }

    @Action(CloseShareListAction)
    closeShareList(ctx: StateContext<SharesListStateModel>, action: CloseShareListAction) {
        const state = ctx.getState();

        const rest = omit([action.key], state.sharesList);

        ctx.patchState({ sharesList: rest });
    }

    @Action(ChangeSelectionAction)
    changeSelection({ getState, setState }: StateContext<SharesListStateModel>, action: ChangeSelectionAction) {
        const state = getState();
        let cloneSelected = [...state.sharesList[state.currentShareList].selected];

        const index = cloneSelected.find((shareSelected) => shareSelected.id === action.shareId);

        if (!index) {
            cloneSelected.push({
                id: state.shares[action.shareId].id,
                name: state.shares[action.shareId].name,
                isin: state.shares[action.shareId].isin,
            });
        } else {
            cloneSelected = cloneSelected.filter((shareSelected) => shareSelected.id !== action.shareId);
        }

        setState(onChangeCurrentShareList({ selected: cloneSelected }));
    }

    @Action(SelectAllAction)
    selectAll({ getState, setState }: StateContext<SharesListStateModel>) {
        const state = getState();

        let selectedIds = state.sharesList[state.currentShareList].trendingShares;

        if (!state.sharesList[state.currentShareList].isTrending) {
            selectedIds = state.sharesList[state.currentShareList].shares.values;
        }

        const selectedShares = uniq(selectedIds).map((id) => ({
            id: state.shares[id].id,
            name: state.shares[id].name,
            isin: state.shares[id].isin,
        }));

        setState(onChangeCurrentShareList({ selected: [...selectedShares] }));
    }

    @Action(UnselectAllAction)
    unselectAll({ setState }: StateContext<SharesListStateModel>) {
        setState(onChangeCurrentShareList({ selected: [] }));
    }

    @Action(ResetCurrentDisplayValuesAction)
    resetCurrentDisplayValue({ getState, setState, dispatch }: StateContext<SharesListStateModel>, action: ResetCurrentDisplayValuesAction) {
        setState(onChangeCurrentShareList({ displayValues: getState().defaultDisplayValues }));
        if (action.resetSearch) {
            dispatch(new SearchAction(true));
        }
    }

    @Action(SaveDefaultDisplayValuesAction)
    saveDefaultDisplayValues({ patchState }: StateContext<SharesListStateModel>, action: SaveDefaultDisplayValuesAction) {
        patchState({ defaultDisplayValues: action.displayValues });
    }

    @Action(ApplyDisplayValuesAction)
    applyDisplayValues({ getState, setState }: StateContext<SharesListStateModel>, action: ApplyDisplayValuesAction) {
        const state = getState();

        // Delete all sort value if indicator isn't displayed anymore
        const displayOptions = [
            ...action.displayValues,
            ...SHARES_DEFAULT_SORT_OPTIONS.map((option) => option.value),
        ].map((value) => reject((val) => val === undefined, value));
        const sortValues = state.sharesList[state.currentShareList].sortValues.map((value) => reject((val) => val === undefined, value));
        const newSortValues = sortValues.filter((sortValue) => displayOptions.find((displayValue) => equals(omit(['direction', 'sortCode'], sortValue), displayValue)));

        setState(onChangeCurrentShareList({
            displayValues: action.displayValues,
            sortValues: newSortValues,
        }));
    }

    @Action(ApplySortValuesAction)
    applySortValues({ setState }: StateContext<SharesListStateModel>, action: ApplySortValuesAction) {
        setState(onChangeCurrentShareList({ sortValues: action.sortValues }));
    }

    @Action(SetSelectedSavedSearchIdAction)
    SetSelectedSavedSearchIdAction({ setState }: StateContext<SharesListStateModel>, action: SetSelectedSavedSearchIdAction) {
        setState(onChangeCurrentShareList({ currentSavedSearchId: action.selectedSavedSearchId }));
    }

    @Action(ResetSelectedSavedSearchIdAction)
    ResetSelectedSavedSearchIdAction({ setState }: StateContext<SharesListStateModel>) {
        setState(onChangeCurrentShareList({ currentSavedSearchId: -1 }));
    }

    @Action(SetFiltersAction)
    setFilters({ setState }: StateContext<SharesListStateModel>, action: SetFiltersAction) {
        setState(onChangeCurrentShareList({
            filters: action.filters,
            filtersCount: action.filters.reduce((acc, filter: AdvancedFilterGeneric) => {
                if (filter.value.values) {
                    const filterValue = filter.value?.values;
                    return Array.isArray(filterValue) && filterValue?.length > 0
                        ? acc + filterValue?.length
                        : acc + 1;
                }
                if (filter.value.periods) {
                    return filter.value.periods.reduce((acc2) => acc2 + 1, acc);
                }
                if (typeof filter?.value?.value !== 'undefined' && filter?.value?.value !== null) {
                    return acc + 1;
                }
                if (filter.value.min || filter.value.max) {
                    return acc + 1;
                }
                if (filter.value.properties && filter.value.properties.length) return acc + filter.value.properties.length;
                return acc;
            }, 0),
            isTrending: false,
        }));
    }

    @Action(ResetFiltersAction)
    resetFilters({ setState }: StateContext<SharesListStateModel>) {
        const userCurrency = this.authenticationFacade.getUserCurrencySnapshot();
        setState(onChangeCurrentShareList({
            filters: SHARES_DEFAULT_FILTERS(userCurrency ?? 'EUR'),
            filtersCount: SHARES_DEFAULT_FILTERS(userCurrency ?? 'EUR').length,
        }));
    }

    @Action(GetSavedSearchesAction)
    getSavedSearches({ getState, patchState, setState }: StateContext<SharesListStateModel>, action: GetSavedSearchesAction) {
        const state = getState();
        return this.savedSearchesService.getSavedSearches(state.facets)
            .pipe(
                tap((result) => {
                    const currentId = typeof action.library === 'string'
                        ? result.find((x) => x.name === action.library)?.id ?? -1
                        : action?.library?.id ?? -1;
                    if (action.forceUpdate) {
                        patchState({
                            savedSearches: result,
                        });
                        setState(onChangeCurrentShareList({ currentSavedSearchId: currentId }));
                    } else {
                        patchState({
                            savedSearches: result,
                        });
                    }
                }),
            );
    }

    @Action(PostSavedSearchAction)
    postSavedSearch({ dispatch }: StateContext<SharesListStateModel>, action: PostSavedSearchAction) {
        return this.savedSearchesService.postSavedSearch(action.library, action.filters)
            .pipe(
                tap(() => {
                    dispatch(new GetSavedSearchesAction(action.library, true));
                }),
            );
    }

    @Action(PutSavedSearchAction)
    putSavedSearch({ dispatch }: StateContext<SharesListStateModel>, action: PutSavedSearchAction) {
        return this.savedSearchesService.putSavedSearch(action.library, action.filters)
            .pipe(
                tap(() => {
                    dispatch(new GetSavedSearchesAction(action.library));
                }),
            );
    }

    @Action(DeleteSavedSearchAction)
    deleteSavedSearch({ dispatch }: StateContext<SharesListStateModel>, action: DeleteSavedSearchAction) {
        return this.savedSearchesService.deleteSavedSearch(action.id)
            .pipe(
                tap(() => {
                    dispatch(new GetSavedSearchesAction());
                }),
            );
    }

    @Action(SetViewTypeAction)
    setViewType({ setState }: StateContext<SharesListStateModel>, action: SetViewTypeAction) {
        setState(onChangeCurrentShareList({ viewType: action.viewType }));
    }

    @Action(AddShareAtIndexAction)
    addShareAtIndex({ getState, patchState, dispatch }: StateContext<SharesListStateModel>, action: AddShareAtIndexAction) {
        const currentState = getState();
        const { displayValues } = currentState.sharesList[currentState.currentShareList];
        const fields = this.indicatorsUtilsService.createFieldsForRequest(displayValues, [], SHARES_SEARCH_FIELDS.join(','));
        return this.sharesService.search({
            fields,
            q: '',
            filters: [
                {
                    exclude: false,
                    property: 'id',
                    union: true,
                    values: [action.shareId.toString()],
                },
            ],
            sorts: [],
            searchUniverse: currentState.sharesList[currentState.currentShareList].currentUniverse,
            subSegmentation: 'ALL',
            includeMetadata: false,
            startAt: 0,
            size: 1,
            requestId: '',
        })
            .pipe(
                tap((results) => {
                    const state = getState();
                    patchState({
                        shares: mergeLeft(indexBy(prop('id'), results.values), state.shares),
                        sharesList: {
                            ...state.sharesList,
                            [state.currentShareList]: {
                                ...state.sharesList[state.currentShareList],
                                shares: {
                                    ...state.sharesList[state.currentShareList].shares,
                                    values: [...(action.index !== -1
                                        ? insert(action.index + 1, action.shareId, state.sharesList[state.currentShareList].shares.values)
                                        : [
                                            ...state.sharesList[state.currentShareList].shares.values,
                                            action.shareId,
                                        ])],
                                },
                            },
                        },

                    });
                }),
                tap(() => {
                    dispatch(new GetRiskIndicatorsAction([action.shareId]));
                }),
            );
    }
}
