import { Injectable } from '@angular/core';
import {
    Actions,
    ofActionSuccessful,
    Select,
    Store,
} from '@ngxs/store';
import {
    map,
    Observable,
} from 'rxjs';

import { ENVESTBOARD_UNIVERSE_ID } from '~/app/core/constants/shares.constants';
import { ViewType } from '~/app/shared/enums/view-type.enum';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { AdvancedFilterValue } from '~/app/shared/types/advanced-filter/advanced-filter-value.type';
import {
    SearchUniverseShareSearchRequestModel,
} from '~/app/shared/types/api/search-universe/search-universe-share-search-request-model.type';
import { FacetFamily } from '~/app/shared/types/facet/facet-family.type';
import { SavedSearch } from '~/app/shared/types/saved-search/saved-search.type';
import { SearchQueryBody } from '~/app/shared/types/search/search-query-body.type';
import { SearchUniverse } from '~/app/shared/types/search/search-universe.type';
import { ShareSelected } from '~/app/shared/types/shares/share-selected.type';
import { Share } from '~/app/shared/types/shares/share.type';
import { DisplayValue } from '~/app/shared/types/sort-display/display-value.type';
import { SortValue } from '~/app/shared/types/sort-display/sort-value.type';
import { SubSegmentation } from '~/app/shared/types/sub-segmentation.type';
import { TabItem } from '~/app/shared/types/tab-item.type';


import {
    AddShareAtIndexAction,
    AddShareListAction,
    AddSharesToSearchUniverseByShareIdsAction,
    AddSharesToSearchUniversesByShareIdsAction,
    AddSharesToSearchUniversesByShareSearchAction,
    ApplyDisplayValuesAction,
    ApplySortValuesAction,
    AutocompleteSearchAction,
    ChangeActiveShareListAction,
    ChangeSearchUniverseAction,
    ChangeSelectionAction,
    ChangeSubSegmentationAction,
    CreateSearchUniverseByShareIdsAction,
    CreateSearchUniverseByShareSearchAction,
    DeleteSavedSearchAction,
    DeleteSearchUniverseAction,
    EmptyShares,
    GetMetaDataAction,
    GetPromotedSharesBySubSegmentationIdAction,
    GetSavedSearchesAction,
    GetSearchUniverseAction,
    GetSearchUniversesAction,
    GetSubSegmentationsWithSharesAction,
    PostSavedSearchAction,
    PutSavedSearchAction,
    ResetCurrentDisplayValuesAction,
    ResetFiltersAction,
    ResetSelectedSavedSearchIdAction,
    SaveDefaultDisplayValuesAction,
    SearchAction,
    SelectAllAction,
    SetFiltersAction,
    SetIsTrendingAction,
    SetSelectedSavedSearchIdAction,
    SetViewTypeAction,
    UnselectAllAction,
    UpdateSearchUniverseAction,
} from './shares-list.actions';
import {
    SearchUniverseModel,
    SharesListState,
} from './shares-list.state';

@Injectable({
    providedIn: 'root',
})
export class SharesListFacade {
    @Select(SharesListState.getCurrentSearchUniverse)
    public currentUniverse$?: Observable<SearchUniverse>;

    @Select(SharesListState.getCurrentSavedSearchId)
    public currentSelectedSavedChangeId$!: Observable<number>;

    @Select(SharesListState.getCurrentSearchUniverseId)
    public currentUniverseId$?: Observable<number>;

    @Select(SharesListState.getSearchUniverses)
    public universes$!: Observable<SearchUniverseModel[]>;

    @Select(SharesListState.getCurrentSubSegmentation)
    public currentSubSegmentation$?: Observable<number>;

    @Select(SharesListState.currentFilters)
    public currentFilters$!: Observable<AdvancedFilterGeneric[]>;

    @Select(SharesListState.filtersCount)
    public currentFiltersCount$!: Observable<number>;

    @Select(SharesListState.savedSearches)
    public savedSearches$!: Observable<SavedSearch[]>;

    @Select(SharesListState.getSubSegmentations)
    public subSegmentations$!: Observable<TabItem[]>;

    @Select(SharesListState.getSelectedShareIds)
    public selectedShareIds$?: Observable<Array<number>>;

    @Select(SharesListState.getSelectedShares)
    public selectedShares$?: Observable<Array<ShareSelected>>;

    @Select(SharesListState.isTrending)
    public isTrending$?: Observable<boolean>;

    @Select(SharesListState.facets)
    public facets$?: Observable<FacetFamily[]>;

    @Select(SharesListState.getQuery)
    public query$!: Observable<string>;

    @Select(SharesListState.getSubSegmentationsWithShares)
    public subSegmentationsWithShares$?: Observable<[SubSegmentation & { shares: Share[] }]>;

    @Select(SharesListState.getDisplayIndicatorShare)
    public displayIndicator$!: Observable<Array<DisplayValue>>;

    @Select(SharesListState.getDefaultDisplayValues)
    public defaultDisplayValues$!: Observable<DisplayValue[]>;

    @Select(SharesListState.isLoading)
    public isLoading$?: Observable<boolean>;

    @Select(SharesListState.getShares)
    public shares$!: Observable<Share[]>;

    @Select(SharesListState.isSearchDisabled)
    public isSearchDisabled$!: Observable<boolean>;

    @Select(SharesListState.getPromotedShares)
    public promotedShares$?: Observable<Share[]>;

    @Select(SharesListState.getSelectedShareIds)
    public selected$?: Observable<Array<number>>;

    @Select(SharesListState.isLoadingPromoted)
    public isLoadingPromoted$?: Observable<boolean>;

    @Select(SharesListState.indicatorsIsLoading)
    public indicatorsIsLoading$?: Observable<{ [shareId: string]: boolean }>;

    @Select(SharesListState.currentShareListViewType)
    public currentShareListViewType$?: Observable<ViewType>;

    @Select(SharesListState.getCurrentSortValue)
    public currentSortValue$!: Observable<SortValue[]>;

    @Select(SharesListState.getCurrentSortCount)
    public currentSortCount$?: Observable<number>;

    public universesWithoutDefault$: Observable<SearchUniverseModel[]> = this.universes$
        .pipe(
            map((universes) => universes.filter((item) => item.id !== ENVESTBOARD_UNIVERSE_ID && item.id !== this.getCurrentSearchUniverseIdSnapshot())),
        );

    public currentFiltersTranslated$: Observable<AdvancedFilterGeneric[]> = this.currentFilters$
        .pipe(
            map((filters) => filters.map((item) => {
                const filterName = this.getFacetsSnapshot()
                    .find((facet) => facet.values.find((value) => value.field === item.value.property))
                    ?.values.find((value) => value.field === item.value.property)
                    ?.name ?? item.name;

                const familyField = item.familyField ?? this.getFacetsSnapshot()
                    .find((facet) => facet.values.find((value) => value.field === item.value.property))
                    ?.field;
                return {
                    ...item,
                    name: filterName,
                    familyField,
                };
            })),
        );

    constructor(
        private store: Store,
        private action$: Actions,
    ) { }

    public changeActiveShareList(key: string): Observable<unknown> {
        return this.store.dispatch(new ChangeActiveShareListAction(key));
    }

    public changeSearchUniverse(universeId: number): Observable<unknown> {
        return this.store.dispatch(new ChangeSearchUniverseAction(universeId));
    }

    public createSearchUniverseByShareIds(
        name: string,
        sharesId: number[],
        mustStay?: boolean,
        emptySelected?: boolean,
    ): Observable<unknown> {
        return this.store.dispatch(new CreateSearchUniverseByShareIdsAction(name, sharesId, mustStay, emptySelected));
    }

    public createSearchUniverseByShareSearch(
        name: string,
        shareSearch: SearchUniverseShareSearchRequestModel,
        mustStay?: boolean,
        emptySelected?: boolean,
    ): Observable<unknown> {
        return this.store.dispatch(new CreateSearchUniverseByShareSearchAction(name, shareSearch, mustStay, emptySelected));
    }

    public updateSearchUniverse(id: number, name: string): Observable<unknown> {
        return this.store.dispatch(new UpdateSearchUniverseAction(id, name));
    }

    public deleteSearchUniverse(universeId: number): Observable<unknown> {
        return this.store.dispatch(new DeleteSearchUniverseAction(universeId));
    }

    public addSharesToSearchUniverse(id: number, sharesId: number[], emptySelected?: boolean): Observable<unknown> {
        return this.store.dispatch(new AddSharesToSearchUniverseByShareIdsAction(id, sharesId, emptySelected));
    }

    public addSharesToSearchUniversesByShareSearch(
        universeids: number[],
        shareSearch: SearchUniverseShareSearchRequestModel,
        clearSelected = true,
        refreshGroups = true,
    ): Observable<unknown> {
        return this.store.dispatch(new AddSharesToSearchUniversesByShareSearchAction(universeids, shareSearch, clearSelected, refreshGroups));
    }

    public addSharesToSearchUniverses(universeids: number[], sharesIds: number[], clearSelected = true, refreshGroups = true): Observable<unknown> {
        return this.store.dispatch(new AddSharesToSearchUniversesByShareIdsAction(universeids, sharesIds, clearSelected, refreshGroups));
    }

    public setFilters(filters: AdvancedFilterValue[]): Observable<unknown> {
        return this.store.dispatch(new SetFiltersAction(filters));
    }

    public resetFilters(): Observable<unknown> {
        return this.store.dispatch(new ResetFiltersAction());
    }

    public setSelectedSavedSearchId(selectedSavedSearchId: number): Observable<unknown> {
        return this.store.dispatch(new SetSelectedSavedSearchIdAction(selectedSavedSearchId));
    }

    public resetSelectedSavedSearchId(): Observable<unknown> {
        return this.store.dispatch(new ResetSelectedSavedSearchIdAction());
    }

    public changeSubSegmentation(id: number): Observable<unknown> {
        return this.store.dispatch(new ChangeSubSegmentationAction(id));
    }

    public setIsTrending(isTrending: boolean): Observable<unknown> {
        return this.store.dispatch(new SetIsTrendingAction(isTrending));
    }

    public autocompleteSearch(suggestion: string, resetFilters: boolean = false, resetSubsegmentations = false): Observable<unknown> {
        return this.store.dispatch(new AutocompleteSearchAction(suggestion, resetFilters, resetSubsegmentations));
    }

    public clearAutocompleteSearch(): Observable<unknown> {
        return this.store.dispatch(new AutocompleteSearchAction('', false, false));
    }

    public postSavedSearch(value: string, filters: AdvancedFilterValue[]): Observable<unknown> {
        return this.store.dispatch(new PostSavedSearchAction(value, filters));
    }

    public putSavedSearch(value: { id: number, label: string }, filters: AdvancedFilterValue[]): Observable<unknown> {
        return this.store.dispatch(new PutSavedSearchAction(value, filters));
    }

    public deleteSavedSearch(id: number): Observable<unknown> {
        return this.store.dispatch(new DeleteSavedSearchAction(id));
    }

    public search(reset?: boolean): Observable<unknown> {
        return this.store.dispatch(new SearchAction(reset));
    }

    public getPromotedSharesBySubSegmentationId(): Observable<unknown> {
        return this.store.dispatch(new GetPromotedSharesBySubSegmentationIdAction());
    }

    public changeSelection(shareId: number): Observable<unknown> {
        return this.store.dispatch(new ChangeSelectionAction(shareId));
    }

    public selectAll(): Observable<unknown> {
        return this.store.dispatch(new SelectAllAction());
    }

    public unselectAll(): Observable<unknown> {
        return this.store.dispatch(new UnselectAllAction());
    }

    public getSearchUniverses(): Observable<unknown> {
        return this.store.dispatch(new GetSearchUniversesAction());
    }

    public getSearchUniverse(universeId: number): Observable<unknown> {
        return this.store.dispatch(new GetSearchUniverseAction(universeId));
    }

    public getMetaData(searchUniverse: number | undefined = undefined): Observable<unknown> {
        return this.store.dispatch(new GetMetaDataAction(searchUniverse));
    }

    public getSavedSearches(): Observable<unknown> {
        return this.store.dispatch(new GetSavedSearchesAction());
    }

    public addShareList(key: string, overrideList?: boolean, extraFilters?: AdvancedFilterGeneric[]) {
        return this.store.dispatch(new AddShareListAction(key, overrideList, extraFilters));
    }

    public getSubSegmentationsWithShares(): Observable<unknown> {
        return this.store.dispatch(new GetSubSegmentationsWithSharesAction());
    }

    public emptyShares(sharesId?: number[]): Observable<unknown> {
        return this.store.dispatch(new EmptyShares(sharesId));
    }

    public applyDisplayValues(displayValues: DisplayValue[]): Observable<unknown> {
        return this.store.dispatch(new ApplyDisplayValuesAction(displayValues));
    }

    public resetDisplayValues(resetSearch: boolean = false): Observable<unknown> {
        return this.store.dispatch(new ResetCurrentDisplayValuesAction(resetSearch));
    }

    public applySortValues(sortValues: SortValue[]): Observable<unknown> {
        return this.store.dispatch(new ApplySortValuesAction(sortValues));
    }

    public setViewType(viewType: ViewType): Observable<unknown> {
        return this.store.dispatch(new SetViewTypeAction(viewType));
    }

    public saveDefaultDisplayValues(displayValues: DisplayValue[]): Observable<unknown> {
        return this.store.dispatch(new SaveDefaultDisplayValuesAction(displayValues));
    }

    public addShareAtIndex(shareId: number, index: number): Observable<unknown> {
        return this.store.dispatch(new AddShareAtIndexAction(shareId, index));
    }

    public getSearchQueryBodySnapshot(): SearchQueryBody {
        return this.store.selectSnapshot(SharesListState.getSearchQueryBody);
    }

    public getCreatedUniverseIdSnapshot(): number | undefined {
        return this.store.selectSnapshot(SharesListState.getCreatedUniverseId);
    }

    public getSelectedShareIdsSnapshot(): number[] {
        return this.store.selectSnapshot(SharesListState.getSelectedShareIds);
    }

    public getSearchUniversesSnapshot(): SearchUniverseModel[] {
        return this.store.selectSnapshot(SharesListState.getSearchUniverses);
    }

    public getCurrentSearchUniverseIdSnapshot(): number {
        return this.store.selectSnapshot(SharesListState.getCurrentSearchUniverseId);
    }

    public getCurrentSearchUniverseSnapshot(): SearchUniverse {
        return this.store.selectSnapshot(SharesListState.getCurrentSearchUniverse);
    }

    public getSavedSearchesSnapshot(): SavedSearch[] {
        return this.store.selectSnapshot(SharesListState.savedSearches);
    }

    public getRequestIdOfSharesSnapshot(): string {
        return this.store.selectSnapshot(SharesListState.getRequestIdOfShares);
    }

    public getCurrentFiltersSnapshot(): AdvancedFilterGeneric<unknown>[] {
        return this.store.selectSnapshot(SharesListState.currentFilters);
    }

    public getSubSegmentationsSnapshot(): TabItem[] {
        return this.store.selectSnapshot(SharesListState.getSubSegmentations);
    }

    public getCurrentSubSegmentationSnapshot(): number {
        return this.store.selectSnapshot(SharesListState.getCurrentSubSegmentation);
    }

    public getCurrentFiltersCountSnapshot(): number {
        return this.store.selectSnapshot(SharesListState.filtersCount);
    }

    public getPromotedSharesIdSnapshot() {
        return this.store.selectSnapshot(SharesListState.getPromotedSharesId);
    }

    public getIsTrendingSnapshot(): boolean {
        return this.store.selectSnapshot(SharesListState.isTrending);
    }

    public getAlreadyQuitPromotedShareSnapshot(): boolean {
        return this.store.selectSnapshot(SharesListState.alreadyQuitPromotedShare);
    }

    public getDisplayIndicatorShareSnapshot(): DisplayValue[] {
        return this.store.selectSnapshot(SharesListState.getDisplayIndicatorShare);
    }

    public getCurrentSavedSearchIdSnapshot(): number | null {
        return this.store.selectSnapshot(SharesListState.currentSavedSearchId);
    }

    public getSharesSnapshot(): Share[] {
        return this.store.selectSnapshot(SharesListState.getShares);
    }

    public getFacetsSnapshot(): FacetFamily[] {
        return this.store.selectSnapshot(SharesListState.facets);
    }

    public getCurrentShareList(): string {
        return this.store.selectSnapshot(SharesListState.currentShareList);
    }

    public getUniversesWithoutDefaultSnapshot(): SearchUniverseModel[] {
        return this.store.selectSnapshot(SharesListState.getSearchUniverses).filter((item) => item.id !== ENVESTBOARD_UNIVERSE_ID);
    }

    public onListParameterChange(): Observable<unknown> {
        return this.action$
            .pipe(
                ofActionSuccessful(
                    ApplyDisplayValuesAction,
                    ApplySortValuesAction,
                    AutocompleteSearchAction,
                    ChangeSearchUniverseAction,
                    ChangeSubSegmentationAction,
                    ResetFiltersAction,
                    SetFiltersAction,
                ),
            );
    }

    public onSearchUniverseParameterChange(): Observable<unknown> {
        return this.action$
            .pipe(
                ofActionSuccessful(
                    ChangeSearchUniverseAction,
                ),
            );
    }

    public onGetSavedSearchSuccess(): Observable<unknown> {
        return this.action$
            .pipe(
                ofActionSuccessful(
                    PostSavedSearchAction,
                    GetSavedSearchesAction,
                ),
            );
    }
}
